import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"

const get_GudangList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.GUDANG.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_GudangDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.GUDANG.GET, {id: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_GudangSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.GUDANG.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_GudangList,
  get_GudangDetail,
  post_GudangSave
}